function nimbus_factory(_nimbus_host, _ajax_func, _retry_timeout) {

    /* don't use protocol relative URL */
    var prtcl = (document.location.protocol.slice(0,5) === 'https') ? 'https': 'http';

    /**
     * Function that wraps up an ajax request (needs jquery to work)
     */
    var default_ajax_function = function(_url, _data, _method, _cb) {
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    var response = xhr.responseText;
                    try {
                        response = JSON.parse(xhr.responseText);
                    } catch(e) {}

                    if (response.error) {
                        _cb(response, null);
                    } else if (response.result) {
                        _cb(null, response.result);
                    } else {
                        _cb(null, response);
                    }
                } else {
                    _cb(JSON.parse(xhr.responseText, null));
                }
            }
        };

        xhr.open(_method, _url, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.send(JSON.stringify(_data));
    };

    var my_ajax_request = _ajax_func || default_ajax_function;
    var retry_timeout = _retry_timeout || 6000;
    var retry_timeout_err = 6000;
    var nimbus_host = _nimbus_host || 'nimbus.bitdefender.net';

    /**
     * Function that calls a callback function that expects as a parameter an object containing
     * push_id, agent address and port for long polling.
     *
     * _obj = { push_id: '', address: '', port: '' }
     */
    function get_push_id(_data,  _cb) {
        if (typeof  _data === 'function' && _cb === null) {
            _cb = _data;
            _data = {};
        }

        var url = prtcl + '://' + nimbus_host + '/push/atlas';
        if (prtcl === 'https') {
            url += 'https';
        }

        my_ajax_request(url, _data, 'POST', function(_err, _obj) {
            if (_err) {
                setTimeout(get_push_id, retry_timeout_err, _cb);
            } else {
                _cb(_obj);
            }
        });
    }

    /**
     * Function that expects as a parameter the coordinates for long polling (agent address
     * and port and a push_id), and calls a callback function when a message is received
     *
     * _res = 'string containing the message'
     */
    function start_polling(_coords, _cb) {
        //_coords.address="beta.push.bitdefender.net"; - ONLY FOR BETA. HAVE TO DELETE IN PRODUCTION (OR COMMENT)!
        //_coords.address="push.bitdefender.net";  - PRODUCTION

        var url = prtcl + '://' + _coords.address + ':' + _coords.port + '/poll';
        var data = {
            push_id: _coords.push_id
        };

        my_ajax_request(url, data, 'POST', function(_err, _res) {
            if (_err) {
                // if (_err.error === 'Forbidden') {
                // } else if (_err.error && _err.error.message && _err.error.message === 'Wrong credentials') {
                // } else
                if (_err.error === 'noop') {
                    setTimeout(start_polling, retry_timeout,  _coords, _cb);
                } else {
                    setTimeout(start_polling, retry_timeout_err,  _coords, _cb);
                }
            } else {
                if (_err === "" && _res === null) {
                    setTimeout(start_polling, retry_timeout_err,  _coords, _cb);
                } else {
                    _cb( null, _res);
                    start_polling(_coords, _cb);
                }
            }
        });
    }

    return {
        start_polling: start_polling,
        get_push_id: get_push_id
    };

}
